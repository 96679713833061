import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import * as classes from "../alternating/alternating.module.scss";
import Grid from "../../components/Grid/Grid";
import path from "path";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

const grid = props => {
  const gridData = props.data.grid.frontmatter;
  const filteredSubsections = gridData.children.filter(
    sub => !(sub?.internal?.mediaType !== "text/markdown" || !sub)
  );
  const subsections = filteredSubsections.map(subsection => {
    const data = subsection.childMarkdownRemark;
    return {
      title: data.frontmatter.title,
      showImage: data.frontmatter.hide_featured_image,
      fluid: data.frontmatter.featured_image?.childImageSharp.fluid,
      file: subsection.absolutePath,
      path: `${props.path}/${path.basename(subsection.absolutePath, ".md")}`,
    };
  });

  return (
    <Layout>
      {gridData.featured_image && !gridData.hide_featured_image ? (
        <Img fluid={gridData.featured_image.childImageSharp.fluid} />
      ) : null}
      {props.pageContext.breadCrumb ? (
        <BreadCrumb breadCrumb={props.pageContext.breadCrumb} />
      ) : null}
      <div className={classes.section}>
        <div dangerouslySetInnerHTML={{ __html: props.data.grid.html }} />
      </div>
      <Grid entries={subsections} />
    </Layout>
  );
};

export const query = graphql`
  query($absolutePath: String) {
    grid: markdownRemark(fileAbsolutePath: { eq: $absolutePath }) {
      fileAbsolutePath
      html
      frontmatter {
        title
        hide_featured_image
        featured_image {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        children {
          internal {
            mediaType
          }
          absolutePath
          childMarkdownRemark {
            frontmatter {
              title
              featured_image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default grid;
