import React from "react";
import * as classes from "./Grid.module.scss";
import Img from "gatsby-image";
import { navigate } from "gatsby";
const { Projects: ProjectsClass, Project } = classes;

const Grid = ({ entries }) => {
  return (
    <main className={ProjectsClass}>
      {entries.map(entry => {
        if (entry.fluid == null) {
          throw new Error(`Invalid image in file '${entry.file}'`);
        }
        return (
          <div
            key={entry.path}
            className={Project}
            onClick={() => navigate(entry.path)}
          >
            <div>
              {entry.fluid ? (
                <Img fluid={entry.fluid} />
              ) : (
                <p>Image unavailable</p>
              )}
            </div>
            <h3>{entry.title}</h3>
          </div>
        );
      })}
    </main>
  );
};

export default Grid;
